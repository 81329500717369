$orange : #e94400;
$blue : #2a3c52; 
$grey : #949eaa;
$light-grey : #f7f7f7;

 

.btn {
    border-radius: 20px;
    padding: 10px 20px; 
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    i {
        margin-right: 10px;
    }
    @media(min-width: 768px) { 
        font-size: 15px;
    }
}

.bg-blue {
    background-color: $blue;
}

.bg-light-grey well {
    background-color: $light-grey;
    padding: 40px;
}

.btn {
    transition: 0.2s all;
    border: 0;
    &:focus {
        outline: none !important;
    }
}
.btn-orange, .btn-primary {
    background-color: $orange;
    color: white;
    border: 0;
    &:hover, &:focus {
        background-color: darken($orange, 10%);
        color: #fff;
    }
}
.btn-white {
    background-color: white;
    border-color: $blue;
}
.btn-grey, .btn-secondary {
    background-color: #949eaa;
    color: white;
    &:hover {
        color: white;
        background: darken(#949eaa, 10%);
    }
}
.btn-blue {
    background-color: $blue;
    color: white;   
    &:hover {color: white !important;}
}