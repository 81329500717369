#product-category, #product-product {
    h2 { 
        font-size: 3rem;
    }
    img{
        max-width: 100%;
    }
    .orange-header {
        &:after {
            height: 5px;
            margin-top: 10px;
        }
    }
    .thumbnails{
        margin: 0;
        .thumbnail{
            padding: 0;
            border: 0;
            margin: 0;
            img{
                //border-radius: 10px;
            }
        }
        li:first-child {
            position: relative;
            i {
                position: absolute;
                left: calc(50% - 22.5px);
                top: calc(50% - 22.5px);
            }
        }
       
    }
    #options {
        select {
            font-size: 15px;
            border-radius: 4px;
            font-weight: 500;
            color: $blue;
            border: 2px solid #ccc;
            @media(min-width: 576px) {   
                font-size: 16px;
            }
            &:focus{
                box-shadow: none;
                outline: none;
            }
        }
    }

    .mounts {
        > div {
            vertical-align: top;
            border-radius: 50%;
            border: 2px solid #ccc;
            overflow: hidden;
            width: 25px;
            height: 25px;
            label {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                cursor: pointer;
            }
        }
        input[type=radio].with-font {
            
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        input[type=radio].with-font + label:before {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            content: " ";
            overflow:hidden;
        }
        input[type=radio].with-font:checked + label:before {
            font-family: FontAwesome;
            content: "\f00c";
            font-size: 1.2em;
            color: darkgreen;
        }  
    }
    
    .from-price {
        font-size: 25px;  
        font-weight: 900;  
    }

    &.filtered-category {
            .select {
                position: relative;
                display: inline-block;
                margin-bottom: 15px;
                flex-basis: 90%;
                max-width: 90%;
                margin: 10px;
                @media(min-width: 576px) {   
                   
                    flex-basis: 45%;
                    max-width: 45%;
                }
                @media(min-width: 991px) {   
                   
                    flex-basis: 28%;
                    max-width: 28%;
                }
                @media(min-width: 1199px) {   
                   
                    flex-basis: 20%;
                    max-width: 20%;
                }
            }    
            .select select {
                    font-family: 'Arial';
                    display: inline-block;
                    width: 100%;
                    cursor: pointer;
                    padding: 14px 24px;
                    outline: 0;
                    border: 0px solid $blue;
                    border-radius: 30px;    
                    background: $blue;
                    color: #ffff;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
            }
                .select select::-ms-expand {
                    display: none;
                    }
            .select select:hover,
            .select select:focus {
                color: #fff;
                background: $blue;
            }
                    .select select:disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
            .select_arrow {
                position: absolute;
                top: 18px;
                right: 15px;
                width: 0px;
                height: 0px;
                border: solid #fff;
                border-width: 0 3px 3px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
            .select select:hover ~ .select_arrow,
            .select select:focus ~ .select_arrow {
                border-color: #fff;
            }
            .select select:disabled ~ .select_arrow {
                border-top-color: #fff;
            }
        }
        .product-types{
            flex-wrap: wrap;
            margin: 0 -2px;
            .btn {
                flex-basis: 50%;
                max-width: 50%;
                @media (max-width: 550px){
                    flex-basis:100%;
                max-width: 100%;
                }

                &.active {
                    background-color: $orange;
                }
                flex-basis: 50%;
                border: 2px solid $light-grey;
                border-radius: 20px;
            }
        }
        table {
            background: #fff;
            border: 0px;
            overflow: hidden;
            border-radius: 10px;
            thead {
                background: $blue;
                border: 0px;
                color: #fff;
                th {
                    border: 0px;
                    padding: 10px 20px;
                    padding-left: 30px;
                    &:last-child{
                       padding-left: 10px;
                       width: 100px;
                    }
                    &:first-child{
                        width: auto;
                       }
                }
                
            }

            tbody {
                border: 0px;
                tr {
                    border: 0px;
                    cursor: pointer;
                    
                    td {
                        border: 0px;
                        position: relative;
                        padding-top: 12px;
                        padding-bottom: 12px;
                       
                       &:last-child{
                        font-weight: 600;
                        width: 100px;
                       }
                       &:first-child{
                        padding-left: 30px;
                       }
                       
                       .form-control{
                            border: 2px solid #ccc;
                       }
                       
                    }

                   
                    i {
                        position: absolute;
                        opacity: 0;
                        top: 50%;
                        left: 0;
                        transform: translate(-30px, -50%);
                        font-size: 15px;
                        color: #01d089;
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                    }
                    &.active {
                        td i {
                            opacity: 1;
                            transform: translate(7px, -50%);
                        }
                    }
                }
                
            }
        }
        .product-thumb {
            overflow: hidden;
            .image {
                position: relative;
                background: #fff;
                transition: .2s all cubic-bezier(.075,.82,.165,1);
                img{
                    mix-blend-mode: multiply;
                }
            }
            .caption {
                transition: .2s all cubic-bezier(.075,.82,.165,1);
                position: absolute;
                top: 0; 
                width: 100%;
                height: 100%;
                opacity: 0;
                color: #fff;
                font-size: 20px;
                font-weight: 900;

            }
            &:hover { 
                .caption {
                    opacity: 1;
                }
                .image{
                    background: $orange;
                }
            }
        }

        .masonry-item{
            padding: 15px;
            border-radius: 10px;
            background: #f5f5f5;
            box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.1);
            margin-bottom: 30px;
            &:hover{
                .product-thumb{
                    .caption {
                        opacity: 1;
                    }
                    .image{
                        background: $orange;
                    }
                }
            }
            h4{
                font-size: 18px;
                font-weight: 900;
                padding-top: 10px
            }
        }
        .category-box {
            padding: 15px;
            border-radius: 10px;
            background: #f5f5f5;
            box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.1);
            margin-bottom: 30px;
            &:hover{
                .product-thumb{
                    .caption {
                        opacity: 1;
                    }
                    .image{
                        background: $orange;
                    }
                }
            }
            h4{
                font-size: 18px;
                font-weight: 900;
                padding-top: 10px
            }
        }
}


#frame-modal{
    .modal-frame{
        background: #f5f5f5;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover, &:focus{
            background: #f0f0f0;
        }
        img{
            margin: 0;
        }
        strong{
            font-size: 16px;
            display: block;
            text-align: center;
            padding: 10px 0;
        }

        .select-button{
            display: block;
            padding: 0 0;
            color: $orange;
            font-weight: 900;
        }

    }
}
