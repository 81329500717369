#header {
    box-shadow: 0 1px 5px 2px rgba(0,0,0,0.1);
    position: relative;
    z-index: 2;
    padding: 10px 0;
    #logo {
        padding: 0;
        flex-basis: 100%;
            max-width: 100%;
        a{
            display: block;
            
        }
        @media(max-width: 768px) {
            width: 100px;
        }
    }
    #header-stripe {
        width: 25px;
        background: $orange;
        left:0;
        height: calc(100% - 2px);
    }
    nav.navbar-default {
        background-color: transparent;
        border: 0;
        .navbar-brand>img {
            max-height: 100%;
        }
        @media(max-width: 768px) { 
                width: 100%;
        }
        .navbar-nav>li>a {
            color: $blue;
            font-weight: 700;
            font-size: 15px;
            padding: 9px;
            &:hover, &:focus {
                background-color: transparent;
                color: $orange;
            }
            
            @media (min-width: 1236px) {
                font-size: 18px;
                padding: 15px;
            }
        }
    }
    #category-nav {
        padding: 0;
        @media(max-width: 768px) { 
            overflow-x: hidden;
            text-align:center;
            ul li {
                border-bottom: 1px solid #ccc;
            }
        }
    }

    #search {
        width: 200px;
        margin-left: 20px;
        form{
            display: flex;
        }
        input {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-color: $blue !important;
            padding-left: 25px;
            border-width: 2px;
            border-right: 0;
            font-size: 15px;
            &:focus {
                box-shadow: none;
            }
            &::placeholder {
                color: $blue;
                font-weight: 500;
            }
        }
        button{
            border-width: 2px;
            display: flex;
            align-items: center;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border-color: $blue !important;
            outline: none;
            box-shadow: none;
            &:hover{
                outline: none;
                box-shadow: none;
                i{
                    color: $orange;
                }
            }
            i {
                position: relative;
            }
        }
      
        
    }

    #top-links {
        font-weight: 500;
        .logo { 
            width:200px
        }
        li {
            position: relative;
            a {
                margin-left: 10px;
                i {
                    margin-right: 5px;
                }
                &:hover, &:focus{
                    i{
                        color: $orange;
                    }
                }
            }

            .dropdown-menu{
                
                margin: 0;
                margin-top: 10px;
                padding: 10px 0;
                border: 0;
                border-radius: 0px;
                //border-top: 4px solid $orange;
                border-bottom: 4px solid $orange;
                li{
                    padding: 0 0;
                    margin: 0;
                    a{
                        padding: 5px 20px;
                        margin: 0;
                        font-weight: 500;
                        &:hover, &:focus{
                            background: #fff;
                            color: $orange;
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
            }
            
        }
    }

    
}