.twig-block {
    text-align: center;
    h2 {
        font-size: 25px;
        font-weight: 900;
        margin-top: 0;
        &:after {
            margin: 15px auto;
            @media(min-width: 768px) {   
                margin: 20px 0;
            }
        }
        
        @media(min-width: 768px) {   
            max-width: 70%;
            font-size: 30px;
        }
        @media(min-width: 991px) {   
            font-size: 35px;
        }
        @media(min-width: 1024px) {   
            font-size: 40px;
        }
        
       
        
    }
    .text{
        margin-bottom: 10px;
        @media(min-width: 576px) {   
			max-width: 60%;
            margin: 0 auto;
            margin-bottom: 10px;
		}
		
        @media(min-width: 768px) {   
             max-width: 80%;
             margin: 0;
             margin-bottom: 20px;
        }
        p{
            font-size: 15px;
            margin: 0;
            @media(min-width: 768px) {   
                font-size: 16px;
            }
            @media(min-width: 991px) {   
                font-size: 17px;
            }
            @media(min-width: 1024px) {   
                font-size: 18px;
            }
        }
    }

    @media(min-width: 768px) { 
        text-align: left;

    }
    &.easy {      
        h2 {
            text-align: center;
            margin-bottom: 20px;
            @media(min-width: 768px) {   
                max-width: 100%;
            }
        }
        .icon{
            img {
                height: 40px;
                display: inline-block;
                margin: 10px auto;
                @media(min-width: 576px) {   
                    height: 50px;
                }
                @media(min-width: 768px) {   
                    height: 55px;
                }
                @media(min-width: 991px) {   
                    height: 60px;
                }
               
            }
            h3{
                text-align: center;
                margin: 10px 0;
                font-weight: 600;
                font-size: 18px;
                @media(min-width: 768px) {   
                    font-size: 20px;
                }
                @media(min-width: 991px) {   
                    font-size: 24px;
                }
            }
        }
       
        .text{
            margin: 10px 0;
            @media(min-width: 768px) {   
                 max-width: 90%;
                 margin: 0 auto 10px;
            }
            p{
                font-size: 15px;
                @media(min-width: 768px) {   
                    font-size: 16px;
                }
                @media(min-width: 991px) {   
                    font-size: 18px;
                }
            }
           
        }
    } 

    &.framing {
        
        background-image: url('/image/design/frames.jpg');
        background-color: $blue;
        background-size: 50%;
        background-position: right top;
        background-repeat: no-repeat;
       
        @media(min-width: 1340px) {   
            background-size: 630px auto;
       }
       h2{
           text-shadow: 2px 2px 5px rgba(0,0,0,0.2);
       }
      
    }

    &.photography {
        
        
        background-color: transparent;
        background-size: auto 100%, auto 100%;
        background-position: 70% 0, top right;
        background-repeat: no-repeat, repeat;
        h2 {
            color: $blue;
           
        }
        p {
            color: $blue;
        }
        
        @media(min-width: 768px) { 
            background-image: url(/image/design/camera.jpg), url(/image/design/camera-bg-gradient.jpg);
            
        }
    }
}