@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,700;0,900;1,900&display=swap');

@import 'base/colors.scss';
@import 'sections/header';
@import 'sections/footer.scss';
@import 'sections/twig_blocks';
@import 'pages/product_category';

body {
	color: #2a3c54; 
	font-size: 15px; 
	font-family: Raleway;
	overflow-x: hidden;
	.container {
		max-width: 1340px;
	}

}

.row{
	>div{
		&:before, &:after{
			display: flex;
		}
	}
}


a {
	color: inherit;
	&:hover {
		text-decoration: none;
	}
} 

.orange-header {
	font-weight: 900;
	&::after {
		content : "";
		display: block;
		width: 75px;
		background-color: $orange;
		height: 6px;
		margin-top: 20px;
		height: 4px;
			margin-top: 15px;
		@media(min-width: 768px) {   
			height: 6px;
		margin-top: 20px;
		}
	}
}

.font-black{
	font-weight: 900;
}

.featured-category {

	text-align: center;
	

	@media(min-width: 768px) {   
		text-align: left;
	}
	
	.featured-item{
		.featured-image{
		
			position: relative;
			z-index: 0;
			overflow: hidden;
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $orange;
				z-index: 2;
				transform: translate(0,100%);
				transform-origin: top left;
				mix-blend-mode: multiply;
				transition: .3s all cubic-bezier(.075,.82,.165,1);
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					opacity: 0.3;
			   }
			}
			img{
				z-index: 1;
			}
		}
		h4 {
			font-weight: 900;
			font-size: 20px;
		}
		&:hover{
			.featured-image{
				&:before{
					transform:  translate(0,0);
					
				}
			}
		}
	}
	.orange-header {
        font-size: 25px;
        font-weight: 900;
        margin-top: 0;
       
        
        @media(min-width: 768px) {   
            font-size: 30px;
        }
        @media(min-width: 991px) {   
            font-size: 35px;
        }
        @media(min-width: 1024px) {   
            font-size: 40px;
        }
        
       
        
    }
	
	.text{
		@media(min-width: 576px) {   
			max-width: 60%;
			margin: 0 auto;
		}
		@media(min-width: 768px) {   
			max-width: 100%;
			margin: 0;
		}
        p{
            font-size: 15px;
            margin: 0;
            @media(min-width: 768px) {   
                font-size: 16px;
            }
            @media(min-width: 991px) {   
                font-size: 17px;
            }
            @media(min-width: 1024px) {   
                font-size: 18px;
            }
        }
    }
	

}

.section{
	padding: 30px 0;
	@media(min-width: 768px) {   
		padding: 50px 0;
	}
	@media(min-width: 991px) {   
		padding: 60px 0;
	}
	@media(min-width: 1024px) {   
		padding: 70px 0;
	}
}

.page-header{
	padding: 30px 0;
	margin: 0;
	border: 0;
	background: #f4f4f4;
	margin-bottom: 15px;
	box-shadow: 0 1px 5px 2px rgba(0,0,0,0.1);
	.orange-header {
		font-weight: 900;
		margin: 0;
		&::after {
			margin-top: 10px;
		}
		@media (max-width: 991px){
			font-size: 30px;
		}
		@media (max-width: 767px){
			font-size: 28px;
		}
		@media (max-width: 575px){
			font-size: 25px;
		}
	}
	.text{
		margin-top: 10px;
		font-size: 18px;
		@media (max-width: 991px){
			font-size: 17px;
		}
		@media (max-width: 767px){
			font-size: 16px;
		}
		@media (max-width: 575px){
			font-size: 15px;
		}
		p{
			margin: 0;
		}
	}

}

.w-100 {
	width: 100%;
}
.mw-100 {
	max-width: 100%;
}
.text-white {
	color: #fff;
}
.bg-transparent {
	background-color: transparent;
}

.breadcrumb{
	margin: 0;
    margin-bottom: 30px;
    background: #fff;
	padding: 0;
	padding-bottom: 10px;
	position: relative;
	&:before{
		content: '';
		height: 1px;
		background: #eee;
		position: absolute;
		left: 0;
		top: 100%;
		width: 1000%;
		transform: translateX(-50%);
	}
	li{
		a{
			font-size: 14px;
			&:hover, &:focus{
				color: $orange;
				text-decoration: none;;
			}
		}
	}
}
.form-control{
	height: 45px;
}

.input-group{
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	.form-control{
		height: auto;
	}
	.input-group-btn, .input-group-addon{
		width: auto;
		flex-basis: auto;
		flex-shrink: 0;
		flex-grow: 0;
		
	}
}

fieldset{
	legend{
		font-weight: 900;
		padding-top: 20px;
		padding-bottom: 10px;
		display: block;
	}
}

.panel-group{
    .panel{
        &.panel-default{
            border-radius: 10px;
            border-color: #ddd;
            >.panel-heading{
				background: #f9f9f9;
				font-weight: 900;
                padding: 15px;
                text-transform: uppercase;
                border-radius: 0px;
            }
        }

        h2{
            font-weight: 900;
            font-size: 25px;
            margin-bottom: 20px;
        }

		h4{
			font-weight: 900;
			text-transform: none;
		}
        legend{
            font-weight: 900;
            font-size: 20px;
            margin-bottom: 10px;
        }

        .col-sm-2{
            @media (max-width: 767px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}

.well{
	border-radius: 10px;
	border: 0px;
	padding: 40px;
	box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.1);

	@media(max-width: 991px) {
        padding: 40px 30px;
    }
    @media(max-width: 575px) {
        padding: 20px;
    }
	h1, h2, h3, h4{
		font-weight: 900;
		margin-top: 0;
	}
}

.br-10{
	//border-radius: 10px;
}

.search-settings {
    padding: 30px;
    border: 1px solid #e4e4e4;
    margin-bottom: 30px;
    border-radius: 20px;
}

#checkout-cart{
	#content{
		input{
			min-width: 50px;
		}
	}
}