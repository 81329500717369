@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,700;0,900;1,900&display=swap");
.btn {
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.btn i {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .btn {
    font-size: 15px;
  }
}

.bg-blue {
  background-color: #2a3c52;
}

.bg-light-grey well {
  background-color: #f7f7f7;
  padding: 40px;
}

.btn {
  transition: 0.2s all;
  border: 0;
}

.btn:focus {
  outline: none !important;
}

.btn-orange, .btn-primary {
  background-color: #e94400;
  color: white;
  border: 0;
}

.btn-orange:hover, .btn-orange:focus, .btn-primary:hover, .btn-primary:focus {
  background-color: #b63500;
  color: #fff;
}

.btn-white {
  background-color: white;
  border-color: #2a3c52;
}

.btn-grey, .btn-secondary {
  background-color: #949eaa;
  color: white;
}

.btn-grey:hover, .btn-secondary:hover {
  color: white;
  background: #788493;
}

.btn-blue {
  background-color: #2a3c52;
  color: white;
}

.btn-blue:hover {
  color: white !important;
}

#header {
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  padding: 10px 0;
}

#header #logo {
  padding: 0;
  flex-basis: 100%;
  max-width: 100%;
}

#header #logo a {
  display: block;
}

@media (max-width: 768px) {
  #header #logo {
    width: 100px;
  }
}

#header #header-stripe {
  width: 25px;
  background: #e94400;
  left: 0;
  height: calc(100% - 2px);
}

#header nav.navbar-default {
  background-color: transparent;
  border: 0;
}

#header nav.navbar-default .navbar-brand > img {
  max-height: 100%;
}

@media (max-width: 768px) {
  #header nav.navbar-default {
    width: 100%;
  }
}

#header nav.navbar-default .navbar-nav > li > a {
  color: #2a3c52;
  font-weight: 700;
  font-size: 15px;
  padding: 9px;
}

#header nav.navbar-default .navbar-nav > li > a:hover, #header nav.navbar-default .navbar-nav > li > a:focus {
  background-color: transparent;
  color: #e94400;
}

@media (min-width: 1236px) {
  #header nav.navbar-default .navbar-nav > li > a {
    font-size: 18px;
    padding: 15px;
  }
}

#header #category-nav {
  padding: 0;
}

@media (max-width: 768px) {
  #header #category-nav {
    overflow-x: hidden;
    text-align: center;
  }
  #header #category-nav ul li {
    border-bottom: 1px solid #ccc;
  }
}

#header #search {
  width: 200px;
  margin-left: 20px;
}

#header #search form {
  display: flex;
}

#header #search input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-color: #2a3c52 !important;
  padding-left: 25px;
  border-width: 2px;
  border-right: 0;
  font-size: 15px;
}

#header #search input:focus {
  box-shadow: none;
}

#header #search input::placeholder {
  color: #2a3c52;
  font-weight: 500;
}

#header #search button {
  border-width: 2px;
  display: flex;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-color: #2a3c52 !important;
  outline: none;
  box-shadow: none;
}

#header #search button:hover {
  outline: none;
  box-shadow: none;
}

#header #search button:hover i {
  color: #e94400;
}

#header #search button i {
  position: relative;
}

#header #top-links {
  font-weight: 500;
}

#header #top-links .logo {
  width: 200px;
}

#header #top-links li {
  position: relative;
}

#header #top-links li a {
  margin-left: 10px;
}

#header #top-links li a i {
  margin-right: 5px;
}

#header #top-links li a:hover i, #header #top-links li a:focus i {
  color: #e94400;
}

#header #top-links li .dropdown-menu {
  margin: 0;
  margin-top: 10px;
  padding: 10px 0;
  border: 0;
  border-radius: 0px;
  border-bottom: 4px solid #e94400;
}

#header #top-links li .dropdown-menu li {
  padding: 0 0;
  margin: 0;
}

#header #top-links li .dropdown-menu li a {
  padding: 5px 20px;
  margin: 0;
  font-weight: 500;
}

#header #top-links li .dropdown-menu li a:hover, #header #top-links li .dropdown-menu li a:focus {
  background: #fff;
  color: #e94400;
  outline: none;
  box-shadow: none;
}

#footer {
  padding: 30px 0;
  background: #f5f5f5;
}

#footer a {
  font-size: 14px;
}

#footer a:hover {
  color: #e94400;
}

.twig-block {
  text-align: center;
}

.twig-block h2 {
  font-size: 25px;
  font-weight: 900;
  margin-top: 0;
}

.twig-block h2:after {
  margin: 15px auto;
}

@media (min-width: 768px) {
  .twig-block h2:after {
    margin: 20px 0;
  }
}

@media (min-width: 768px) {
  .twig-block h2 {
    max-width: 70%;
    font-size: 30px;
  }
}

@media (min-width: 991px) {
  .twig-block h2 {
    font-size: 35px;
  }
}

@media (min-width: 1024px) {
  .twig-block h2 {
    font-size: 40px;
  }
}

.twig-block .text {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .twig-block .text {
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .twig-block .text {
    max-width: 80%;
    margin: 0;
    margin-bottom: 20px;
  }
}

.twig-block .text p {
  font-size: 15px;
  margin: 0;
}

@media (min-width: 768px) {
  .twig-block .text p {
    font-size: 16px;
  }
}

@media (min-width: 991px) {
  .twig-block .text p {
    font-size: 17px;
  }
}

@media (min-width: 1024px) {
  .twig-block .text p {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .twig-block {
    text-align: left;
  }
}

.twig-block.easy h2 {
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .twig-block.easy h2 {
    max-width: 100%;
  }
}

.twig-block.easy .icon img {
  height: 40px;
  display: inline-block;
  margin: 10px auto;
}

@media (min-width: 576px) {
  .twig-block.easy .icon img {
    height: 50px;
  }
}

@media (min-width: 768px) {
  .twig-block.easy .icon img {
    height: 55px;
  }
}

@media (min-width: 991px) {
  .twig-block.easy .icon img {
    height: 60px;
  }
}

.twig-block.easy .icon h3 {
  text-align: center;
  margin: 10px 0;
  font-weight: 600;
  font-size: 18px;
}

@media (min-width: 768px) {
  .twig-block.easy .icon h3 {
    font-size: 20px;
  }
}

@media (min-width: 991px) {
  .twig-block.easy .icon h3 {
    font-size: 24px;
  }
}

.twig-block.easy .text {
  margin: 10px 0;
}

@media (min-width: 768px) {
  .twig-block.easy .text {
    max-width: 90%;
    margin: 0 auto 10px;
  }
}

.twig-block.easy .text p {
  font-size: 15px;
}

@media (min-width: 768px) {
  .twig-block.easy .text p {
    font-size: 16px;
  }
}

@media (min-width: 991px) {
  .twig-block.easy .text p {
    font-size: 18px;
  }
}

.twig-block.framing {
  background-image: url("/image/design/frames.jpg");
  background-color: #2a3c52;
  background-size: 50%;
  background-position: right top;
  background-repeat: no-repeat;
}

@media (min-width: 1340px) {
  .twig-block.framing {
    background-size: 630px auto;
  }
}

.twig-block.framing h2 {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.twig-block.photography {
  background-color: transparent;
  background-size: auto 100%, auto 100%;
  background-position: 70% 0, top right;
  background-repeat: no-repeat, repeat;
}

.twig-block.photography h2 {
  color: #2a3c52;
}

.twig-block.photography p {
  color: #2a3c52;
}

@media (min-width: 768px) {
  .twig-block.photography {
    background-image: url(/image/design/camera.jpg), url(/image/design/camera-bg-gradient.jpg);
  }
}

#product-category h2, #product-product h2 {
  font-size: 3rem;
}

#product-category img, #product-product img {
  max-width: 100%;
}

#product-category .orange-header:after, #product-product .orange-header:after {
  height: 5px;
  margin-top: 10px;
}

#product-category .thumbnails, #product-product .thumbnails {
  margin: 0;
}

#product-category .thumbnails .thumbnail, #product-product .thumbnails .thumbnail {
  padding: 0;
  border: 0;
  margin: 0;
}

#product-category .thumbnails li:first-child, #product-product .thumbnails li:first-child {
  position: relative;
}

#product-category .thumbnails li:first-child i, #product-product .thumbnails li:first-child i {
  position: absolute;
  left: calc(50% - 22.5px);
  top: calc(50% - 22.5px);
}

#product-category #options select, #product-product #options select {
  font-size: 15px;
  border-radius: 4px;
  font-weight: 500;
  color: #2a3c52;
  border: 2px solid #ccc;
}

@media (min-width: 576px) {
  #product-category #options select, #product-product #options select {
    font-size: 16px;
  }
}

#product-category #options select:focus, #product-product #options select:focus {
  box-shadow: none;
  outline: none;
}

#product-category .mounts > div, #product-product .mounts > div {
  vertical-align: top;
  border-radius: 50%;
  border: 2px solid #ccc;
  overflow: hidden;
  width: 25px;
  height: 25px;
}

#product-category .mounts > div label, #product-product .mounts > div label {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

#product-category .mounts input[type=radio].with-font, #product-product .mounts input[type=radio].with-font {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#product-category .mounts input[type=radio].with-font + label:before, #product-product .mounts input[type=radio].with-font + label:before {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: " ";
  overflow: hidden;
}

#product-category .mounts input[type=radio].with-font:checked + label:before, #product-product .mounts input[type=radio].with-font:checked + label:before {
  font-family: FontAwesome;
  content: "\f00c";
  font-size: 1.2em;
  color: darkgreen;
}

#product-category .from-price, #product-product .from-price {
  font-size: 25px;
  font-weight: 900;
}

#product-category.filtered-category .select, #product-product.filtered-category .select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  flex-basis: 90%;
  max-width: 90%;
  margin: 10px;
}

@media (min-width: 576px) {
  #product-category.filtered-category .select, #product-product.filtered-category .select {
    flex-basis: 45%;
    max-width: 45%;
  }
}

@media (min-width: 991px) {
  #product-category.filtered-category .select, #product-product.filtered-category .select {
    flex-basis: 28%;
    max-width: 28%;
  }
}

@media (min-width: 1199px) {
  #product-category.filtered-category .select, #product-product.filtered-category .select {
    flex-basis: 20%;
    max-width: 20%;
  }
}

#product-category.filtered-category .select select, #product-product.filtered-category .select select {
  font-family: 'Arial';
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 14px 24px;
  outline: 0;
  border: 0px solid #2a3c52;
  border-radius: 30px;
  background: #2a3c52;
  color: #ffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#product-category.filtered-category .select select::-ms-expand, #product-product.filtered-category .select select::-ms-expand {
  display: none;
}

#product-category.filtered-category .select select:hover,
#product-category.filtered-category .select select:focus, #product-product.filtered-category .select select:hover,
#product-product.filtered-category .select select:focus {
  color: #fff;
  background: #2a3c52;
}

#product-category.filtered-category .select select:disabled, #product-product.filtered-category .select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

#product-category.filtered-category .select_arrow, #product-product.filtered-category .select_arrow {
  position: absolute;
  top: 18px;
  right: 15px;
  width: 0px;
  height: 0px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#product-category.filtered-category .select select:hover ~ .select_arrow,
#product-category.filtered-category .select select:focus ~ .select_arrow, #product-product.filtered-category .select select:hover ~ .select_arrow,
#product-product.filtered-category .select select:focus ~ .select_arrow {
  border-color: #fff;
}

#product-category.filtered-category .select select:disabled ~ .select_arrow, #product-product.filtered-category .select select:disabled ~ .select_arrow {
  border-top-color: #fff;
}

#product-category .product-types, #product-product .product-types {
  flex-wrap: wrap;
  margin: 0 -2px;
}

#product-category .product-types .btn, #product-product .product-types .btn {
  flex-basis: 50%;
  max-width: 50%;
  flex-basis: 50%;
  border: 2px solid #f7f7f7;
  border-radius: 20px;
}

@media (max-width: 550px) {
  #product-category .product-types .btn, #product-product .product-types .btn {
    flex-basis: 100%;
    max-width: 100%;
  }
}

#product-category .product-types .btn.active, #product-product .product-types .btn.active {
  background-color: #e94400;
}

#product-category table, #product-product table {
  background: #fff;
  border: 0px;
  overflow: hidden;
  border-radius: 10px;
}

#product-category table thead, #product-product table thead {
  background: #2a3c52;
  border: 0px;
  color: #fff;
}

#product-category table thead th, #product-product table thead th {
  border: 0px;
  padding: 10px 20px;
  padding-left: 30px;
}

#product-category table thead th:last-child, #product-product table thead th:last-child {
  padding-left: 10px;
  width: 100px;
}

#product-category table thead th:first-child, #product-product table thead th:first-child {
  width: auto;
}

#product-category table tbody, #product-product table tbody {
  border: 0px;
}

#product-category table tbody tr, #product-product table tbody tr {
  border: 0px;
  cursor: pointer;
}

#product-category table tbody tr td, #product-product table tbody tr td {
  border: 0px;
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
}

#product-category table tbody tr td:last-child, #product-product table tbody tr td:last-child {
  font-weight: 600;
  width: 100px;
}

#product-category table tbody tr td:first-child, #product-product table tbody tr td:first-child {
  padding-left: 30px;
}

#product-category table tbody tr td .form-control, #product-product table tbody tr td .form-control {
  border: 2px solid #ccc;
}

#product-category table tbody tr i, #product-product table tbody tr i {
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 0;
  transform: translate(-30px, -50%);
  font-size: 15px;
  color: #01d089;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#product-category table tbody tr.active td i, #product-product table tbody tr.active td i {
  opacity: 1;
  transform: translate(7px, -50%);
}

#product-category .product-thumb, #product-product .product-thumb {
  overflow: hidden;
}

#product-category .product-thumb .image, #product-product .product-thumb .image {
  position: relative;
  background: #fff;
  transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

#product-category .product-thumb .image img, #product-product .product-thumb .image img {
  mix-blend-mode: multiply;
}

#product-category .product-thumb .caption, #product-product .product-thumb .caption {
  transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
}

#product-category .product-thumb:hover .caption, #product-product .product-thumb:hover .caption {
  opacity: 1;
}

#product-category .product-thumb:hover .image, #product-product .product-thumb:hover .image {
  background: #e94400;
}

#product-category .masonry-item, #product-product .masonry-item {
  padding: 15px;
  border-radius: 10px;
  background: #f5f5f5;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

#product-category .masonry-item:hover .product-thumb .caption, #product-product .masonry-item:hover .product-thumb .caption {
  opacity: 1;
}

#product-category .masonry-item:hover .product-thumb .image, #product-product .masonry-item:hover .product-thumb .image {
  background: #e94400;
}

#product-category .masonry-item h4, #product-product .masonry-item h4 {
  font-size: 18px;
  font-weight: 900;
  padding-top: 10px;
}

#product-category .category-box, #product-product .category-box {
  padding: 15px;
  border-radius: 10px;
  background: #f5f5f5;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

#product-category .category-box:hover .product-thumb .caption, #product-product .category-box:hover .product-thumb .caption {
  opacity: 1;
}

#product-category .category-box:hover .product-thumb .image, #product-product .category-box:hover .product-thumb .image {
  background: #e94400;
}

#product-category .category-box h4, #product-product .category-box h4 {
  font-size: 18px;
  font-weight: 900;
  padding-top: 10px;
}

#frame-modal .modal-frame {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

#frame-modal .modal-frame:hover, #frame-modal .modal-frame:focus {
  background: #f0f0f0;
}

#frame-modal .modal-frame img {
  margin: 0;
}

#frame-modal .modal-frame strong {
  font-size: 16px;
  display: block;
  text-align: center;
  padding: 10px 0;
}

#frame-modal .modal-frame .select-button {
  display: block;
  padding: 0 0;
  color: #e94400;
  font-weight: 900;
}

body {
  color: #2a3c54;
  font-size: 15px;
  font-family: Raleway;
  overflow-x: hidden;
}

body .container {
  max-width: 1340px;
}

.row > div:before, .row > div:after {
  display: flex;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

.orange-header {
  font-weight: 900;
}

.orange-header::after {
  content: "";
  display: block;
  width: 75px;
  background-color: #e94400;
  height: 6px;
  margin-top: 20px;
  height: 4px;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .orange-header::after {
    height: 6px;
    margin-top: 20px;
  }
}

.font-black {
  font-weight: 900;
}

.featured-category {
  text-align: center;
}

@media (min-width: 768px) {
  .featured-category {
    text-align: left;
  }
}

.featured-category .featured-item .featured-image {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.featured-category .featured-item .featured-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e94400;
  z-index: 2;
  transform: translate(0, 100%);
  transform-origin: top left;
  mix-blend-mode: multiply;
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .featured-category .featured-item .featured-image:before {
    opacity: 0.3;
  }
}

.featured-category .featured-item .featured-image img {
  z-index: 1;
}

.featured-category .featured-item h4 {
  font-weight: 900;
  font-size: 20px;
}

.featured-category .featured-item:hover .featured-image:before {
  transform: translate(0, 0);
}

.featured-category .orange-header {
  font-size: 25px;
  font-weight: 900;
  margin-top: 0;
}

@media (min-width: 768px) {
  .featured-category .orange-header {
    font-size: 30px;
  }
}

@media (min-width: 991px) {
  .featured-category .orange-header {
    font-size: 35px;
  }
}

@media (min-width: 1024px) {
  .featured-category .orange-header {
    font-size: 40px;
  }
}

@media (min-width: 576px) {
  .featured-category .text {
    max-width: 60%;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .featured-category .text {
    max-width: 100%;
    margin: 0;
  }
}

.featured-category .text p {
  font-size: 15px;
  margin: 0;
}

@media (min-width: 768px) {
  .featured-category .text p {
    font-size: 16px;
  }
}

@media (min-width: 991px) {
  .featured-category .text p {
    font-size: 17px;
  }
}

@media (min-width: 1024px) {
  .featured-category .text p {
    font-size: 18px;
  }
}

.section {
  padding: 30px 0;
}

@media (min-width: 768px) {
  .section {
    padding: 50px 0;
  }
}

@media (min-width: 991px) {
  .section {
    padding: 60px 0;
  }
}

@media (min-width: 1024px) {
  .section {
    padding: 70px 0;
  }
}

.page-header {
  padding: 30px 0;
  margin: 0;
  border: 0;
  background: #f4f4f4;
  margin-bottom: 15px;
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
}

.page-header .orange-header {
  font-weight: 900;
  margin: 0;
}

.page-header .orange-header::after {
  margin-top: 10px;
}

@media (max-width: 991px) {
  .page-header .orange-header {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .page-header .orange-header {
    font-size: 28px;
  }
}

@media (max-width: 575px) {
  .page-header .orange-header {
    font-size: 25px;
  }
}

.page-header .text {
  margin-top: 10px;
  font-size: 18px;
}

@media (max-width: 991px) {
  .page-header .text {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .page-header .text {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .page-header .text {
    font-size: 15px;
  }
}

.page-header .text p {
  margin: 0;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100%;
}

.text-white {
  color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.breadcrumb {
  margin: 0;
  margin-bottom: 30px;
  background: #fff;
  padding: 0;
  padding-bottom: 10px;
  position: relative;
}

.breadcrumb:before {
  content: '';
  height: 1px;
  background: #eee;
  position: absolute;
  left: 0;
  top: 100%;
  width: 1000%;
  transform: translateX(-50%);
}

.breadcrumb li a {
  font-size: 14px;
}

.breadcrumb li a:hover, .breadcrumb li a:focus {
  color: #e94400;
  text-decoration: none;
}

.form-control {
  height: 45px;
}

.input-group {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.input-group .form-control {
  height: auto;
}

.input-group .input-group-btn, .input-group .input-group-addon {
  width: auto;
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 0;
}

fieldset legend {
  font-weight: 900;
  padding-top: 20px;
  padding-bottom: 10px;
  display: block;
}

.panel-group .panel.panel-default {
  border-radius: 10px;
  border-color: #ddd;
}

.panel-group .panel.panel-default > .panel-heading {
  background: #f9f9f9;
  font-weight: 900;
  padding: 15px;
  text-transform: uppercase;
  border-radius: 0px;
}

.panel-group .panel h2 {
  font-weight: 900;
  font-size: 25px;
  margin-bottom: 20px;
}

.panel-group .panel h4 {
  font-weight: 900;
  text-transform: none;
}

.panel-group .panel legend {
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .panel-group .panel .col-sm-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.well {
  border-radius: 10px;
  border: 0px;
  padding: 40px;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
  .well {
    padding: 40px 30px;
  }
}

@media (max-width: 575px) {
  .well {
    padding: 20px;
  }
}

.well h1, .well h2, .well h3, .well h4 {
  font-weight: 900;
  margin-top: 0;
}

.search-settings {
  padding: 30px;
  border: 1px solid #e4e4e4;
  margin-bottom: 30px;
  border-radius: 20px;
}

#checkout-cart #content input {
  min-width: 50px;
}
